import React from 'react';
import './css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faDribbble, faLinkedin, faYoutube, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer class="site-footer" style={{backgroundColor: '#008219'}}>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>Sobre <i class="fa-solid fa-circle-info"></i></h6>
            <p class="text-justify">O Município de Gurjão está situado na Microrregião do Cariri Oriental, distante 218.10 km da Capital João Pessoa. Possui uma área territorial de 335.45 km² e limita-se com os municípios de Soledade, Boa Vista, São João do Cariri, Parari, Santo André e Juazeirinho. Em 1669 com a doação de uma sesmaria, foi iniciada a sua colonização. Seu nome primitivo foi Timbaúba do Gurjão, depois Timbaúba, e simplesmente Gurjão, em homenagem ao Coronel Antônio José de Farias Gurjão, proprietário das terras do primeiro núcleo populacional. Duas grandes epidemias de cólera ocorreram na região uma em 1856 e outra no ano de 1862, ocasião em que foi feita uma promessa a São Sebastião, caso o local não fosse atingido pelo mal, construiriam em sua homenagem a capela e o escolheriam como padroeiro. A graça foi alcançada e os moradores logo se apressaram em cumprir a promessa. Este acolhedor município está situado na zona mais seca do estado, e suas principais atividades econômicas são: a agropecuária, o artesanato e, em especial, a caprinovinocultura – principal fonte de subsistência da cidade.</p>
           
          </div>

     

          <div class="col-xs-6 col-md-3">
            <h6>Links úteis <i class="fa-solid fa-user"></i></h6>
            <ul class="footer-links">
            <li><Link  to={"/"}>Contratos</Link></li>
  
           <li><Link  to={"/"}>Transparência Fiscal</Link></li>
           <li><Link className="dropdown-item" to={"/"}>Codigo Tributario</Link></li>
           
            </ul>
          </div>
        </div>
        <hr></hr>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 - Câmara Municipal de Gurjão | CNPJ: 11.891.058/0001-99 - Controle de dados
         <a href="/#"> <i class="fa-brands fa-dev fa-fade"></i> BIGSTAR SOFTWARES.</a>.
            </p>
          </div>

          <div class="col-md-2 col-sm-6 col-xs-12">

            <ul class="social-icons">
            <li><a className="facebook" href=""><FontAwesomeIcon icon={faFacebook} /></a></li>
            <li><a className="instagram" href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
            <li><a className="youtube" href=""><FontAwesomeIcon icon={faYoutube} /></a></li>
            <li><a className="whatsapp" href="#"><FontAwesomeIcon icon={faWhatsapp} /></a></li>
            </ul>
            
          </div>
        </div>
      </div>    
</footer>
 
  );
}

export default Footer;
