import React, { useEffect, useState } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import './css/NewsGrid.css';
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';


function removePTags(html) {

  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}


export default function Noticias() {
  const [noticias, setNoticias] = useState([]);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          imagem: data[key].imagem,
          data: data[key].data,
        }));


        const noticiasOrdenadas = noticiasArray.sort((a, b) => new Date(b.data) - new Date(a.data));

        setNoticias(noticiasOrdenadas);
      }
    });
  }, []);


  const ordenarNoticiasPorData = (noticias) => {
    return noticias.sort((a, b) => compareAsc(parseISO(b.data), parseISO(a.data)));
  };

  const renderNoticias = (noticia, index) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 180) + "..."
      : noticia.descricao;

    const descricaoSemPTags = removePTags(truncatedDescricao);

    const tituloParaURL = noticia.titulo
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, '-')
      .toLowerCase();

    const isLargeNews = index === 0;
    const isTwoColumns = index === 1 || index === 2;
    const isNormal = index > 2;


    const descricaoNoticia = isTwoColumns ? noticia.descricao.slice(0, 190) + "..." : truncatedDescricao;


    return (
      <div
        className={`news-card`}
        key={noticia.id}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <a href={`/noticias/${tituloParaURL}/${noticia.id}`} rel="noopener noreferrer">
          {isLargeNews && isMouseOver && (
            <div className="new-label">NOVO!</div>
          )}
          <img src={noticia.imagem} alt={noticia.titulo} />
          <h2>{noticia.titulo}</h2>
          <p>{removePTags(descricaoNoticia)}</p>
        
            <button type="button" className="btn btn-primary">LEIA MAIS</button>
          
        </a>
      </div>
    );
  };

  const renderUltimasNoticias = () => {

    const noticiasOrdenadas = ordenarNoticiasPorData(noticias);

    const ultimasNoticias = noticiasOrdenadas.slice(0, 3);

    return ultimasNoticias.map(renderNoticias);
  };

  const renderEstatico = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 55) + "..."
      : noticia.descricao;

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div className="news-card" key={noticia.id}>
        <a href="#">
          <img src={noticia.imagem} alt={noticia.titulo} />
          <h2>{noticia.titulo}</h2>
          <p>{descricaoSemPTags}</p>
        </a>
      </div>
    );
  };
  const hasNoticias = noticias && noticias.length > 0;


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  const serviceData = [


    {
      icon: './imagens/icons/publicacao.png',
      name: 'Decretos',
      href: '/legislacao/decretos',
    },
    {
      icon: './imagens/icons/leis.png',
      name: 'Leis Municipais',
      href: '/legislacao/leismunicipais',
    },
    {
      icon: './imagens/icons/diario.png',
      name: 'Requerimentos',
      href: '/',
    },
    {
      icon: './imagens/icons/documentos.png',
      name: 'Lei Organica',
      href: '/legislacao/leiorganica',
    },

    {
      icon: './imagens/icons/transmissao.png',
      name: 'Transmissões',
      href: 'https://www.facebook.com/camaradegurjaopb',
    },

    
 



  ];

  const noticiasEstatico = [
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },

  ];


  return (


    <div className="news-grid-container2">
      <div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button class="button-title"  data-text="Awesome">
            <span class="actual-text">&nbsp;&nbsp;FIQUE INFORMADO&nbsp;</span>
            <span aria-hidden="true" class="hover-text">&nbsp;&nbsp;FIQUE&nbsp;INFORMADO&nbsp;</span>
          </button>
        </div>







        {hasNoticias ? (
          <div className="news-grid">
            {renderUltimasNoticias()}
          </div>
        ) : (
          <div className="news-grid">
            {noticiasEstatico.map(renderEstatico)}
          </div>
        )}
      </div>

      <div style={{alignItems: 'center', justifyContent: 'center'}}>


        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <button class="button-title" style={{fontSize: "1.2em"}} data-text="Awesome">
            <span class="actual-text">&nbsp; ACESSO RAPIDO&nbsp;</span>
            <span aria-hidden="true" class="hover-text">&nbsp;&nbsp;ACESSO&nbsp;RAPIDO&nbsp;</span>
          </button>
        </div>
        <div className="service-grid3">
          {serviceData.map((service, index) => (
            <Link style={{ textDecoration: "none" }} to={service.href} >
              <div className="service-card" >

                <a><img src={service.icon} alt={service.name} />
                  <p>{service.name}</p></a>

              </div>
            </Link>

          ))}
        </div>

        <Link to={"/noticias/todas"} onClick={scrollToTop}>
          <div className="butao">
          <button class="buttonp" style={{backgroundColor: "green"}}>
              LER TODAS AS NOTICIAS
              <svg fill="currentColor" viewBox="0 0 24 24" class="iconp">
                <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
              </svg>
            </button>
          </div>
        </Link>

      </div>



    </div>
  );
}
