import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, onValue} from 'firebase/database';
import firebaseApp from '../adm/admin/firebaseConfig';

const mesesNumeros = {
	janeiro: 1,
	fevereiro: 2,
	marco: 3,
	abril: 4,
	maio: 5,
	junho: 6,
	julho: 7,
	agosto: 8,
	setembro: 9,
	outubro: 10,
	novembro: 11,
	dezembro: 12,
  };

export default function Portarias() {

	const [newsData, setNewsData] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(2000);
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
	const [selectedYear, setSelectedYear] = useState(''); 


	useEffect(() => {
	  const fetchData = async () => {
		try {
		  const databaseInstance = getDatabase(firebaseApp);
		  const noticiasRef = ref(databaseInstance, 'portarias');
		
		  onValue(noticiasRef, (snapshot) => {
			const data = snapshot.val();
			
			
			if (data) {
			  const newsArray = Object.keys(data).map((key) => ({
				id: key,
				title: data[key].nome, 
				mes: data[key].mes,   
				ano: data[key].ano,
				href: data[key].href
			  }));
			  setNewsData(newsArray);
			}
		  });
		} catch (error) {
		  console.error('Erro ao carregar notícias:', error);
		 
		}
	  };
  
	
	  fetchData();
	}, []); 


	const paginatedNewsData = newsData.slice(0, itemsPerPage);

	const handleItemsPerPageChange = (e) => {
	  setItemsPerPage(parseInt(e.target.value, 10));
	};
  
	const handleSort = (key) => {
	  let direction = 'asc';
	  if (sortConfig.key === key && sortConfig.direction === 'asc') {
		direction = 'desc';
	  }
	  setSortConfig({ key, direction });
	};
  
	const handleYearChange = (e) => {
	  setSelectedYear(e.target.value);
	};
	const uniqueYears = [...new Set(newsData.map((news) => news.ano))];
  
	const filteredNewsData = () => {
	  let filteredItems = [...paginatedNewsData];
  
	  if (selectedYear) {
		filteredItems = filteredItems.filter((news) => news.ano === selectedYear);
	  }
  
	
	  filteredItems.sort((a, b) => {
		if (a.ano === b.ano) {
		  return mesesNumeros[b.mes] - mesesNumeros[a.mes];
		}
		return b.ano - a.ano;
	  });
  
	  return filteredItems;
	};
  
	const sortedNewsData = () => {
	  const sortableItems = [...filteredNewsData()];
  
	 return sortableItems;
	};

  return (

    <main>
    
    <section class="ftco-section">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-6 text-center mb-5">
          <br/> <br/>
		  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<button class="button-title" data-text="Awesome">
									<span class="actual-text">&nbsp; PORTARIAS MUNICIPAIS&nbsp;</span>
									<span aria-hidden="true" class="hover-text">&nbsp;&nbsp;PORTARIAS&nbsp;MUNICIPAIS&nbsp;</span>
								</button>
							</div>
				</div>
			</div>
			<div className="row">
            <div className="col-md-12">
              <div className="table-wrap">
                <label htmlFor="itemsPerPage">Itens por página:</label>
                <select
                  id="itemsPerPage"
                  name="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
					  <option value="2000">Filtrar por todos</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                
                </select>

                
                <label htmlFor="yearSelector">  - Filtrar por ano:</label>
                <select
                  id="yearSelector"
                  name="yearSelector"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  <option value="">Todos os anos</option>
                  {uniqueYears.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>

                <table className="table">
                  <thead className="thead-primary">
                    <tr>
                      <th onClick={() => handleSort('title')}>Nome</th>
                      <th onClick={() => handleSort('mes')}>Mês</th>
                      <th onClick={() => handleSort('ano')}>Ano</th>
                      <th>Arquivo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedNewsData().map((news) => (
                      <tr key={news.id}>
                        <td>{news.title}</td>
                        <td>{news.mes}</td>
                        <td>{news.ano}</td>
                        <td><a href={"https://camaradegurjao.pb.gov.br/" + news.href}>Download</a></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br /> <br />
              </div>
            </div>
          </div>
      <br/> <br/> <br/> <br/>
		</div>
	</section>
  
  </main>
  
  )
}

