import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/ViewNoticia.css';
import ShareButton from './ShareButton';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import LoadingScreen from 'react-loading-screen'
const db = getDatabase(firebaseApp);

function removePTags(html) {

  return html ? html.replace(/<p>/g, '').replace(/<\/p>/g, '') : '';
}

export default function ViewNoticia() {
  const [isLoading, setIsLoading] = useState(true);
  const [noticias, setNoticias] = useState({});
  const [formattedDate, setFormattedDate] = useState('Carregando...');

  const { id } = useParams();
  const noticiasRef = ref(db, `${KEY_REF}/vereadores/${id}`);

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const snapshot = await get(noticiasRef);
        if (snapshot.exists()) {
          const noticiaData = snapshot.val();

          const parsedDate = new Date(noticiaData.data);

          if (!isNaN(parsedDate)) {
            const formattedDate = format(parsedDate, 'dd/MM/yyyy');
            setFormattedDate(formattedDate);
          }

          setNoticias({
            nome: noticiaData.nome,
            descricao: noticiaData.descricao,
            partido: noticiaData.partido,

            imagem: noticiaData.documentoUrl,

          });
          setIsLoading(false);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNoticia();
  }, [noticiasRef]);


  const descricaoSemPTags = removePTags(noticias.descricao);




  const [noticiasRender, setNoticiasRender] = useState([]);





  return (
    <>
      {isLoading ? (
        <LoadingScreen
          loading={true}
          bgColor='white'
          spinnerColor='#9ee5f8'
          textColor='#676767'
          logoSrc='/logo512.png'

        >


        </LoadingScreen>

      ) : (
        <>
          <div className="container my-4 mx-auto" style={{ maxWidth: '1600px' }}>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="row">
                    <div className="col-md-5" >
                      <img
                        src={noticias.imagem}
                        alt={noticias.nome}
                        className="card-img-top img-fluid d-none d-md-block"
                        style={{ margin: "5%", marginLeft: 80, maxWidth: '200px', borderRadius: 10 }}
                      />


                    </div>
                    <div className="col-md-6">
                      <div className="card-body">
                        <h3 className="d-none d-md-block card-title">{noticias.nome}</h3>
                        <h4 className="d-none d-md-block card-title" style={{ fontSize: 14 }}><b>Partido:</b> {noticias.partido}</h4>

                        <p className="d-none d-md-block card-text text-justify" style={{ padding: "1%" }}>{noticias.descricao}</p>


                      </div>
                    </div>

                  </div>
                  <div className="d-md-none text-center">
                    <img
                      src={noticias.imagem}
                      alt={noticias.nome}
                      className="card-img-top img-fluid"
                      style={{ maxWidth: '300px', borderRadius: 10 }}
                    />
                    <div className="text-center mt-3">
                      <h3 className="card-title">{noticias.nome}</h3>
                      <h4 className="card-title" style={{ fontSize: 14 }}><b>Partido:</b> {noticias.partido}</h4>

                      <p className="card-text text-justify " style={{ padding: "5%" }}>{noticias.descricao}</p>


                      <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-square-envelope"></i> E-MAIL</button>
                      <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-phone" style={{ color: "#27b300" }}></i> TELEFONE </button>
                      <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-clock"></i> HORARIOS</button>

                    </div>
                  </div>
                  <div style={{ margin: "2%", display: "flex", justifyContent: "center" }}>
                    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
                      <i class="fa-solid fa-square-envelope"></i> E-MAIL
                    </button>

                    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
                      <i class="fa-solid fa-phone" style={{ color: "#27b300" }}></i> TELEFONE
                    </button>
                    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
                      <i class="fa-solid fa-clock"></i> HORÁRIOS
                    </button>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </>
      )}



    </>
  );
}
