import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/ViewNoticia.css';
import ShareButton from './ShareButton';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import Backup from './Backup';

const db = getDatabase(firebaseApp);

function removePTags(html) {

  return html ? html.replace(/<p>/g, '').replace(/<\/p>/g, '') : '';
}

export default function TodasNoticias() {


  const [noticiasRender, setNoticiasRender] = useState([]);

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          data: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          imagem: data[key].imagem,
        }));
        setNoticiasRender(noticiasArray);
      }
    });
  }, []);

  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 55) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (

      <div class="col-md-6" key={noticia.id}>
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-primary-emphasis">NOTICIAS</strong>
            <h5 class="mb-0">{noticia.titulo}</h5>
            <div class="mb-1 text-body-secondary">{noticia.data}</div>
            <p class="card-text mb-auto">{descricaoSemPTags}</p>
            <a href={`/noticias/${tituloParaURL}/${noticia.id}`} rel="noopener noreferrer" class="icon-link gap-1 icon-link-hover stretched-link">
              Coontinue Lendo
              <svg class="bi"></svg>
            </a>
          </div>
          <div className="col-auto d-none d-lg-block">
            <img
              src={noticia.imagem}
              alt={noticia.titulo}
              className="bd-placeholder-img zoomed-image"
              width="200"
              height="250"
              role="img"
              aria-label="Placeholder: Thumbnail"
              focusable="false"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderUltimasNoticias = () => {
    const ultimasNoticias = noticiasRender.slice().reverse();
    return ultimasNoticias.map(renderNoticias);
  };



  return (

    <>


      <main class="container">
        <div class="row mb-2" style={{ marginTop: "5%" }}>
          <div class="text-with-gradient" style={{ marginBottom: 15 }}>
            <div class="gradient-line"></div>
            <div class="text-container">
              <span>EXIBINDO TODAS AS NOTICIAS</span>
            </div>
            <div class="gradient-line"></div>
          </div>

          {renderUltimasNoticias()}


        </div>


      </main>

    </>
  );
}
