import React from 'react';

function ShareButton() {
  const pageUrl = encodeURIComponent(window.location.href);

  const handleShareFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
    window.open(facebookUrl, '_blank', 'width=600,height=400');
  };

  const handleShareWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=Confira está noticia: ${pageUrl}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
          <>
      
    <div class="btn-group" style={{marginTop: "50px"}}>     

        <button onClick={handleShareFacebook} type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-brands fa-facebook"></i> Compartilhar </button>
        <button onClick={handleShareWhatsApp}type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-brands fa-whatsapp fa-fade" style={{color: "#27b300"}}></i> Compartilhar </button>
    </div>
    </>
  );
}

export default ShareButton;
