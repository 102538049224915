import React from 'react'
import './css/Transparencia.css';
import { Link } from 'react-router-dom';

export default function SalaEmpreendedor() {
    return (

        <>
            <br /><br />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button class="button-title" data-text="Awesome">
                    <span class="actual-text">&nbsp; TRANSPARÊNCIA&nbsp;</span>
                    <span aria-hidden="true" class="hover-text">&nbsp;&nbsp;TRANSPARÊNCIA&nbsp;</span>
                </button>
            </div>

            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Canais de Atendimento</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação) - Lei Nº 13.460 (Carta de Serviços)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-3 py-3">
                        <div class="col">
                            <a href="#" class="stretched-link bs-solicitar-informacoes-link text-decoration-none custom-card">
                                <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                    <div class="card-body">
                                        <i class="fas fa-info-circle fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">e-SIC</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col">
                            <a href="#" target="_top" class="stretched-link bs-ouvidoria-link text-decoration-none custom-card">
                                <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                    <div class="card-body">
                                        <i class="fas fa-phone-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Ouvidoria</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col">
                            <a href="#" class="stretched-link bs-portaldeservicos-link text-decoration-none custom-card">
                                <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                    <div class="card-body">
                                        <i class="fas fa-envelope fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Carta de Serviços</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>



            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Receitas e Despesas</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação) - Lei Complementar Nº 131 (Transparência)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-3 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Extra?e=101084%2C101084&ctx=101084%2C101084&Tab=3&isModal=false&tabs=off" class="stretched-link bs-receitas-orcamentarias-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Receita Orçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Extra?e=101084%2C101084&ctx=101084%2C101084&Tab=3&isModal=false&tabs=off" class="stretched-link bs-receitas-extraorcamentarias-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Receita Extraorçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Fixada?e=101084%2C101084&ctx=101084%2C101084&Tab=1&isModal=false" class="stretched-link bs-despesas-orcamentarias-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Despesa Orçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Extra?e=101084%2C101084&ctx=101084%2C101084&Tab=4&isModal=false" class="stretched-link bs-despesas-extraorcamentarias-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Despesa Extraorçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Pagamentos?e=101084%2C101084&ctx=101084%2C101084&Tab=6&isModal=false" class="stretched-link bs-documentos-de-pagamento-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Pagamentos</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Atos, publicações, pessoal, compras e convênios</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/leismunicipais"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-balance-scale fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Leis Municipais</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/decretos"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Decretos</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/leiorganica"}>
                                        <a class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                            <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Lei Organica</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Emendas Impositivas</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/FolhaPag/Index/QuadroFuncional?e=101084&ctx=101084&Tab=1&isModal=false" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Pessoal</h3>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/diariosoficial"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Publicações</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/arquivos/contratos"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Contratos</h3>
                                        </a>
                                    </Link>

                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </section>

            <section class="marginPD" >

                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Regulamentações</h4>
                            <p class="mb-0">Normas, Regulamentações, Pareceres, Planos e Informativos</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">RREO</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">RGF</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">LOA</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">LDO</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </section>

            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Regulamentações</h4>
                            <p class="mb-0">Normas, Regulamentações, Pareceres, Planos e Informativos</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Regulamentação da LAI</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Regulamentação das Diárias</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Renúncias Fiscais</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Informações de LGPD</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Tabela de Valores das Diárias</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </>

    )
}
