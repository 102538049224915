import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/ViewNoticia.css';
import ShareButton from './ShareButton';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import LoadingScreen from 'react-loading-screen'
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const db = getDatabase(firebaseApp);

function removePTags(html) {

  return html ? html.replace(/<p>/g, '').replace(/<\/p>/g, '') : '';
}

export default function ViewNoticia() {
  const [noticias, setNoticias] = useState({});
  const [formattedDate, setFormattedDate] = useState('Carregando...');
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const databaseInstance = getDatabase(firebaseApp);
  const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias/${id}`);
  const [noticiasRender, setNoticiasRender] = useState([]);

  useEffect(() => {
    const fetchNoticia = async () => {

      try {
        const snapshot = await get(noticiasRef);
        if (snapshot.exists()) {
          const noticiaData = snapshot.val();

          const parsedDate = new Date(noticiaData.data);

          if (!isNaN(parsedDate)) {
            const formattedDate = format(parsedDate, 'dd/MM/yyyy');
            setFormattedDate(formattedDate);
          }

          setNoticias({
            titulo: noticiaData.titulo,
            descricao: noticiaData.descricao,
            autor: noticiaData.autor,
            fonte: noticiaData.fonte,
            imagem: noticiaData.imagem,
            imagem1: noticiaData.imagem1,
            imagem2: noticiaData.imagem2,
            imagem3: noticiaData.imagem3,
            imagem4: noticiaData.imagem4,
            data: noticiaData.data,
          });

          setIsLoading(false);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNoticia();
  }, [noticiasRef]);




  const descricaoSemPTags = removePTags(noticias.descricao);






  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          data: data[key].data,
          titulo: data[key].titulo,
          fonte: data[key].fonte,
          descricao: data[key].descricao,
          imagem: data[key].imagem,
        }));
        setNoticiasRender(noticiasArray);
      }
    });
  }, []);

  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 55) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (

      <div class="col-md-6" key={noticia.id}>
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-primary-emphasis">NOTICIAS</strong>
            <h5 class="mb-0">{noticia.titulo}</h5>
            <div class="mb-1 text-body-secondary">{noticia.data}</div>
            <p class="card-text mb-auto">{descricaoSemPTags}</p>
            <a href={`/noticias/${tituloParaURL}/${noticia.id}`} target="_blank" rel="noopener noreferrer" class="icon-link gap-1 icon-link-hover stretched-link">
              Coontinue Lendo
              <svg class="bi"></svg>
            </a>
          </div>
          <div className="col-auto d-none d-lg-block">
            <img
              src={noticia.imagem}
              alt={noticia.titulo}
              className="bd-placeholder-img zoomed-image"
              width="200"
              height="250"
              role="img"
              aria-label="Placeholder: Thumbnail"
              focusable="false"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderUltimasNoticias = () => {
    const ultimasNoticias = noticiasRender.slice(-4);
    return ultimasNoticias.map(renderNoticias);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  return (

    <>
      {isLoading ? (
        <LoadingScreen
          loading={true}
          bgColor='white'
          spinnerColor='#9ee5f8'
          textColor='#676767'
          logoSrc='/logo512.png'

        >


        </LoadingScreen>

      ) : (
        <>
          <div className="container text-center">

            <div className="row justify-content-center" style={{ marginTop: 10 }}>
              <div className="col-md-9">
                <div className="card shadow card shadow mb-2">
                  <Grid container spacing={isMobile ? 1 : 1} marginTop={2}>
                    <Grid item xs={12} md={12}>
                      <Card sx={{ maxWidth: '100%', marginLeft: 2, marginRight: 2 }}>
                        <CardMedia
                          component="img"
                          image={noticias.imagem}
                          alt={`${noticias.imagem}`}
                          width={'80%'}

                        />

                      </Card>
                    </Grid>

                  </Grid>

                  <div className="card-body">
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'sans-serif' }}> <i class="fa-regular fa-newspaper fa-fade" style={{ color: "#1153c5" }}></i> {noticias.titulo} </Typography>
                    <small className="text-body-secondary">Publicado em {formattedDate}, {noticias.autor}</small>



                    <div className="card-text custom-justified" dangerouslySetInnerHTML={{ __html: descricaoSemPTags }} />

                    <Grid container spacing={isMobile ? 2 : 4} marginTop={2}>
                      {[noticias.imagem1, noticias.imagem2, noticias.imagem3, noticias.imagem4].map(
                        (img, index) => (
                          <Grid item xs={12} md={6} key={index}>
                            <Card sx={{ maxWidth: '100%' }}>
                              {typeof img === 'string' && img !== '' ? (
                                <CardMedia
                                  component="img"
                                  image={img}
                                  alt={`${noticias.titulo} - Imagem ${index + 1}`}
                                  width={'90%'}
                                />
                              ) : (
                                <>
                                </>
                              )}
                            </Card>
                          </Grid>
                        )
                      )}
                    </Grid>


                    <Typography sx={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'sans-serif', marginTop: 5 }}> <i class="fa-regular fa-newspaper fa-fade" style={{ color: "#1153c5", marginRight: 5 }}></i>{noticias.fonte} </Typography>

                    <div className="d-flex justify-content-between align-items-center">
                      <ShareButton />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <main class="container">

            <h3 className="titulo_p" style={{ marginTop: "30px" }}>
              <i className="fa-solid fa-newspaper"></i> Noticias Relacionadas
            </h3>

            <div class="row mb-2">

              {renderUltimasNoticias()}


            </div>


          </main>
          <div class="col-lg-2 col-md-2 mx-auto">
            <a href="#" class="btn btn-primary my-2"><i class="fa-solid fa-circle-up fa-beat-fade"></i> Voltar ao topo</a>
            <br />  <br />
          </div>
        </>
      )}
    </>
  );
}
