import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Noticias from "./Noticias";
import Videos from "./Videos";
import LayoutBanner from "./LayoutBanner";
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { Link } from 'react-router-dom';
import Meio from './Meio';
import BannerRH from './BannerRH';
import GoTopo from './GoTopo';

function removePTags(html) {

  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

function Novidades() {

  const [noticias, setNoticias] = useState([]);
  const [avisos, setAvisos] = useState([]);


  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/licitacoes`);
    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          date: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          numero: data[key].numero,
          modalidade: data[key].modalidade,
          arquivo: data[key].arquivo,
          horario: data[key].horario,
          documentoUrl: data[key].documentoUrl,
          ativo: data[key].ativo,
        }));

        const noticiasAtivas = noticiasArray.filter((noticia) => noticia.ativo);

        const noticiasOrdenadas = noticiasAtivas.sort((a, b) => new Date(b.data) - new Date(a.data));

        setNoticias(noticiasOrdenadas);
      }
    });

    const avisosRef = ref(databaseInstance, `${KEY_REF}/avisos_sociais`);
    onValue(avisosRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const avisosArray = Object.keys(data).map((key) => ({
          id: key,
          date: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          numero: data[key].numero,
          modalidade: data[key].modalidade,
          arquivo: data[key].arquivo,
          baixavel: data[key].baixavel,
          horario: data[key].horario,
          documentoUrl: data[key].documentoUrl,
          ativo: data[key].ativo,
        }));

        const avisosAtivos = avisosArray.filter((aviso) => aviso.ativo);
        const avisosOrdenados = avisosAtivos.sort((a, b) => new Date(b.data) - new Date(a.data));

        setAvisos(avisosOrdenados);
      }
    });
  }, []);




  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 800) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div class="d-flex text-body-secondary pt-3" key={noticia.id}>
        <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="60" height="60"><i class="fa-solid fa-book fa-xl"></i></a>
        <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "1%", marginRight: "2%" }}>
          <strong class="d-block text-gray-dark">{noticia.titulo} - {noticia.numero}</strong>
          <br />{truncatedDescricao}
          <strong class="d-block text-gray-dark"><br />{noticia.date} - {noticia.horario} - {noticia.modalidade}  <a href={noticia.documentoUrl}> - <i class="fa-solid fa-file-arrow-down fa-beat-fade"></i> BAIXE O EDITAL AQUI</a></strong>
          <strong class="d-block text-gray-dark"></strong>
        </p>

      </div>

    );
  };

  const renderAvisos = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 800) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div class="d-flex text-body-secondary pt-3" key={noticia.id}>
        <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="60" height="60"><i class="fa-solid fa-quote-left"></i></a>
        <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "1%", marginRight: "2%" }}>
          <strong class="d-block text-gray-dark">{noticia.titulo} - {noticia.numero}</strong>
          <br />{truncatedDescricao}
          <strong class="d-block text-gray-dark"><br />{noticia.date} - {noticia.horario} - {noticia.modalidade} <a href={noticia.documentoUrl}> {noticia.baixavel ? <span> - <i class="fa-solid fa-file-arrow-down fa-beat-fade"></i> ARQUIVO BAIXÁVEL</span> : null}</a></strong>
          <strong class="d-block text-gray-dark"></strong>
        </p>
      </div>
    );
  };


  const renderUltimosAvisos = () => {
    if (avisos.length === 0) {
      return (
        <div class="alert alert-warning" role="alert">
          Não há eventos sociais ou avisos importantes para os próximos dias. Verifique novamente mais tarde.
        </div>
      );
    }

    const ultimasNoticias = avisos.slice().reverse();
    return ultimasNoticias.map(renderAvisos);
  };

  const renderUltimasNoticias = () => {
    if (noticias.length === 0) {
      return (
        <div class="alert alert-warning" role="alert">
          Não há licitações disponíveis nos próximos dias. Verifique novamente mais tarde.
        </div>
      );
    }

    const ultimasNoticias = noticias.slice(-3);
    return ultimasNoticias.map(renderNoticias);
  };



  return (


    <>

      <main class="container">
        <div class="d-flex align-items-center p-3 my-3 text-white  rounded shadow-sm" style={{backgroundColor: "#0101D1"}}>
          <a width="100" height="100"><i class="fa-solid fa-comments" ></i></a>

          <div class="lh-1" style={{ marginLeft: "1%" }}>
            <h7>AVISOS SOCIAIS E EVENTOS</h7>
          </div>
        </div>

        <div class="my-3 p-3 bg-body rounded shadow-sm">
          <h6 class="border-bottom pb-2 mb-0">Mantenha-se informado por dentro das últimas novidades e eventos importantes em nossa comunidade a ser realizada.</h6>

          {renderUltimosAvisos()}

          <small class="d-block text-end mt-3">
            <Link to={"/avisos"}>TODOS OS AVISOS</Link>

          </small>
        </div>

      </main>









    </>
  );
}

export default Novidades;
