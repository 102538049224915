import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import VLibras from "@djpfs/react-vlibras";
import Cabecalho from "./componentes/Cabecalho";
import Inicio from "./componentes/Inicio";
import Footer from "./componentes/Footer";
import PaginaNaoEncontrada from "./componentes/PaginaNaoEncontrada";
import ViewNoticia from "./componentes/ViewNoticia";
import Transparencia from "./componentes/Transparencia";
import CodigoTributario from "./componentes/legislacao/CodigoTributario";
import Decretos from "./componentes/legislacao/Decretos";
import Reque from "./componentes/legislacao/Requerimentos";
import DiarioOficial from "./componentes/legislacao/DiarioOficial";
import LeisMunicipais from "./componentes/legislacao/Leismunicipais";
import LeiOrganica from "./componentes/legislacao/Leiorganica";
import Portarias from "./componentes/legislacao/Portarias";
import Editais from "./componentes/arquivos/Editais";
import LDO from "./componentes/arquivos/LDO";
import LOA from "./componentes/arquivos/LOA";
import RGF from "./componentes/arquivos/RGF";
import RREO from "./componentes/arquivos/RREO";
import Contratos from "./componentes/arquivos/Contratos";
import Frota from "./componentes/arquivos/Frota";
import TodasNoticias from "./componentes/TodasNoticias";
import Avisos from './componentes/Avisos';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Quadro from './componentes/arquivos/Quadro';
import ViewVereador from "./componentes/ViewVereador";
import Mocoes from './componentes/legislacao/Mocoes';
import ProjetoDeLei from './componentes/legislacao/ProjetoDeLei';
import Pedidos from './componentes/legislacao/PedidosInfo';
import firebaseApp, { KEY_REF } from './componentes/adm/admin/firebaseConfig';
import LoadingScreen from 'react-loading-screen'
import { getDatabase, ref, get, onValue } from 'firebase/database';
import * as MuiIcons from '@mui/icons-material';

function App() {
  const database = getDatabase(firebaseApp);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);


  useEffect(() => {
    const dbRef = ref(database, `${KEY_REF}/config/`);
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        setConfig(snapshot.val());
      } else {
        setConfig(null);
      }
      setLoading(false);
    });
    
  
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingScreen
      loading={true}
      bgColor='white'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc='/logo512.png'

    >


    </LoadingScreen>;
  }


  return (

    <>
      <Router>

        <Cabecalho config={config} />
        <FloatingWhatsApp
          phoneNumber="+55 (83) 90000-0000"
          accountName="Câmara de Gurjão"
          avatar="logo512.png"
          statusMessage="Geralmente Responde em 30 minutos"
          allowEsc
          allowClickAway
          notification
          notificationSound="true"
          messageDelay="1"
          placeholder="Escreva uma mensagem..."
          chatMessage="Bem-vindos! Em que posso ajudar?"
          notificationDelay="10" />

        <VLibras forceOnload={true}></VLibras>

        <Routes>

          <Route path="/" element={<Inicio />} />
          <Route path="/noticias/:titulo/:id" element={<ViewNoticia />} />
          <Route path="/vereadores/:id" element={<ViewVereador />} />
          <Route path="/noticias/todas" element={<TodasNoticias />} />
          <Route path="/transparencia" element={<Transparencia />} />
          <Route path="/avisos" element={<Avisos />} />

          <Route path="/legislacao/codigotributario" element={<CodigoTributario />} />
          <Route path="/legislacao/decretos" element={<Decretos />} />
          <Route path="/legislacao/diariosoficial" element={<DiarioOficial />} />
          <Route path="/legislacao/leiorganica" element={<LeiOrganica />} />
          <Route path="/legislacao/leismunicipais" element={<LeisMunicipais />} />
          <Route path="/legislacao/portarias" element={<Portarias />} />
          <Route path="/legislacao/pedidos_info" element={<Pedidos />} />
          <Route path="/legislacao/requerimentos" element={<Reque />} />
          <Route path="/legislacao/mocoes" element={<Mocoes />} />
          <Route path="/legislacao/projetodelei" element={<ProjetoDeLei />} />

          <Route path="/arquivos/editais" element={<Editais />} />
          <Route path="/arquivos/contratos" element={<Contratos />} />
          <Route path="/arquivos/ldo" element={<LDO />} />
          <Route path="/arquivos/loa" element={<LOA />} />
          <Route path="/arquivos/rgf" element={<RGF />} />
          <Route path="/arquivos/rreo" element={<RREO />} />
          <Route path="/arquivos/frota" element={<Frota />} />
          <Route path="/arquivos/quadro" element={<Quadro />} />

          <Route path="*" element={<PaginaNaoEncontrada />} />

        </Routes>


        <Footer />



      </Router>


    </>
  );
}



export default App;